<template>
  <div id="app">
    <MainNavbar @darkTheme="handleThemeSwitch" v-if="$route.name !== 'dummy'" />
    <router-view class="app-view" />
    <MainFooter v-if="$route.name !== 'dummy'" />
  </div>
</template>
<script>
import MainNavbar from "@/components/MainNavbar.vue";
import MainFooter from "@/components/NewMainFooter.vue";
import { mapActions } from "vuex";
export default {
  data: () => ({
    darkMode: false,
  }),
  components: { MainNavbar, MainFooter },
  methods: {
    ...mapActions(["fetchTournaments"]),
    handleThemeSwitch(event) {
      this.darkMode = event;
      // document.body.setAttribute("ui-theme", this.darkMode ? "dark" : "light");
    },
    heightMobileFix() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
  },
  mounted() {
    // document.body.setAttribute("ui-theme", "light");
    this.fetchTournaments();
  },
};
</script>
<style lang="scss">
body {
  margin: 0;
}

#app {
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  color: var(--text-primary);
  background-color: var(--interface-primary);
  transition: color 0.5s ease;
  transition: background-color 0.5s ease;
}
</style>
