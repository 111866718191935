<template>
  <div class="navbar-container">
    <b-navbar
      class="main-navbar"
      :class="{ 'flat-bottom': $route.name === 'Schedule' }"
      fixed="top"
    >
      <router-link
        to="/"
        v-if="
          $route.name === 'home' ||
          $route.name === '404' ||
          $mq === 'lg' ||
          $mq === 'xl'
        "
      >
        <img src="@/assets/images/png/logo.png" class="navbar-logo" />
      </router-link>
      <div class="route-name" v-else>
        {{ currentRouteName }}
      </div>
      <b-navbar-nav>
        <div class="left" v-if="$mq === 'lg' || $mq === 'xl'">
          <template v-for="(item, index) in routes">
            <b-nav-item
              v-if="!item?.isDropdown"
              :key="index"
              :active="item.name === $route.name"
              :to="item.route"
              >{{ item.title }}</b-nav-item
            >
            <b-nav-item-dropdown text="Расписание" left :key="index" v-else>
              <b-dropdown-item
                v-for="(subItem, index) in getTournaments"
                :key="index"
                :to="`/schedule?leagueId=${subItem.id}`"
              >
                {{ subItem.name }}
              </b-dropdown-item>
            </b-nav-item-dropdown>
          </template>
        </div>
        <div class="right" v-if="$mq === 'lg' || $mq === 'xl'">
          <b-nav-item class="disabled"
            ><Button class="special small"
              >Зарегистрироваться</Button
            ></b-nav-item
          >
        </div>
        <SidebarButton
          v-b-toggle.mobile-sidebar
          v-if="$mq !== 'lg' && $mq !== 'xl'"
        />
      </b-navbar-nav>
    </b-navbar>
    <div class="mobile-sidebar" v-if="$mq !== 'lg' && $mq !== 'xl'">
      <b-sidebar
        :visible="isSidebarOpened"
        id="mobile-sidebar"
        no-header
        backdrop
        right
      >
        <template #default="{ hide }">
          <div class="sidebar-header">
            <router-link to="/"
              ><img src="@/assets/images/png/logo.png" class="navbar-logo"
            /></router-link>
            <SidebarButton @click="hide" variant="cross" />
          </div>
          <div class="sidebar-body">
            <ul class="nav flex-column">
              <li
                v-for="(navItem, index) in routes"
                :key="`mobile-navbar-item-${index}`"
                class="nav-item"
                :class="{ active: navItem.name === $route.name }"
              >
                <router-link :to="navItem.route">
                  {{ navItem.title }}</router-link
                >
                <ul
                  v-if="getTournaments?.length && navItem.name === 'Schedule'"
                  class="nav flex-column sub-items"
                >
                  <li
                    v-for="(subItem, index) in getTournaments"
                    :key="index"
                    class="nav-item sub-item"
                    :class="{ active: +$route.query?.id === subItem.id }"
                  >
                    <router-link :to="`/schedule?leagueId=${subItem.id}`">
                      {{ subItem.name }}</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="sidebar-footer">
            <!-- <Button class="special small" @click="$router.push('/apply')">
              Зарегистрироваться
            </Button> -->
          </div>
        </template>
      </b-sidebar>
    </div>
  </div>
</template>
<script>
import SidebarButton from "./SidebarButton.vue";
import { mapGetters } from "vuex";

export default {
  name: "MainNavbar",
  components: { SidebarButton },
  data: () => ({
    isSidebarOpened: false,
  }),
  computed: {
    ...mapGetters(["getTournaments"]),
    ...mapGetters(["getApplyStep"]),
    ...mapGetters(["getScheduleName"]),
    routes() {
      return [
        { name: "home", title: "Главная", route: "/" },
        {
          name: "Schedule",
          title: "Расписание",
          route: "/schedule",
          isDropdown: true,
        },
        { name: "Teams", title: "Команды", route: "/teams" },
        {
          name: "PlayersRating",
          title: "Топ игроков",
          route: "/players_rating",
        },
        { name: "Contacts", title: "Контакты", route: "/contacts" },
      ];
    },
    currentRouteName() {
      if (this.$route.name === "Apply") {
        const steps = [
          "Основная информация",
          "Участники команды",
          "Правила и соглашения",
        ];
        return steps[this.getApplyStep];
      }
      if (this.$route.name === "Success") {
        return "Заявка отправлена";
      }
      if (this.$route.name === "Schedule" && this.getScheduleName) {
        return this.getScheduleName;
      }
      return this.routes.find((route) => route.name === this.$route.name)
        ?.title;
    },
  },
};
</script>
<style lang="scss" scoped>
.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: default;
  a,
  button {
    pointer-events: none;
    user-select: none;
  }
}
</style>
