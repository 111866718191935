import Vue from "vue";
import VueRouter from "vue-router";
import MainView from "@/views/MainView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: MainView,
  },
  // {
  //   path: "/apply",
  //   name: "Apply",
  //   component: () => import("@/views/ApplicationView.vue"),
  //   meta: { title: "IBS Cyber League - Регистрация" },
  // },
  {
    path: "/teams",
    name: "Teams",
    component: () => import("@/views/TeamsView.vue"),
    meta: { title: "IBS Cyber League - Команды" },
  },
  {
    path: "/players_rating",
    name: "PlayersRating",
    component: () => import("@/views/PlayersRatingView.vue"),
    meta: { title: "IBS Cyber League - Топ игроков" },
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import("@/views/ContactsView.vue"),
    meta: { title: "IBS Cyber League - Контакты" },
  },
  {
    path: "/oops",
    name: "Oops",
    component: () => import("@/views/OopsView.vue"),
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: () => import("@/views/ScheduleView.vue"),
    props: (route) => ({
      selectedLeagueId: route.query.leagueId,
    }),
    meta: { title: "IBS Cyber League  - Расписание" },
  },
  {
    path: "/success",
    name: "Success",
    component: () => import("@/views/ApplicationFinal.vue"),
    meta: { title: "IBS Cyber League - Заявка отправлена" },
  },
  {
    path: "*",
    name: "404",
    component: () => import("@/views/Page404.vue"),
    meta: { title: "IBS Cyber League - 404" },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

const DEFAULT_TITLE = "IBS Cyber League — корпоративный кибертурнир";

// eslint-disable-next-line no-unused-vars
router.afterEach((to, _) => {
  Vue.nextTick(() => {
    document.title = to?.meta.title || DEFAULT_TITLE;
  });
});

export default router;
