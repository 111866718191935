import Vue from "vue";
import Vuex from "vuex";
import MatchesApi from "@/services/matches.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tournaments: [],
    applyStep: 0,
    scheduleName: "",
    isDarkTheme: true,
    isTournamentsLoading: false,
  },
  getters: {
    getTournaments: ({ tournaments }) => tournaments,
    getApplyStep: ({ applyStep }) => applyStep,
    getScheduleName: ({ scheduleName }) => scheduleName,
    isDarkTheme: ({ isDarkTheme }) => isDarkTheme,
    isTournamentsLoading: ({ isTournamentsLoading }) => isTournamentsLoading,
  },
  mutations: {
    SET_TOURNAMENTS(state, data) {
      state.tournaments = data;
    },
    SET_APPLY_STEP(state, data) {
      state.applyStep = data;
    },
    SET_SCHEDULE_NAME(state, data) {
      state.scheduleName = data;
    },
    SET_DARK_THEME(state, data) {
      state.isDarkTheme = data;
    },
    SET_LOADING(state, data) {
      state.isTournamentsLoading = data;
    },
  },
  actions: {
    fetchTournaments({ commit }) {
      commit("SET_LOADING", true);
      return MatchesApi.loadTournaments()
        .then((data) => {
          commit("SET_TOURNAMENTS", data);
          commit("SET_LOADING", false);
        })
        .catch(() => {
          commit("SET_LOADING", false);
        });
    },
    setApplyStep({ commit }, step) {
      commit("SET_APPLY_STEP", step);
    },
    setScheduleName({ commit }, name) {
      commit("SET_SCHEDULE_NAME", name);
    },
  },
  modules: {},
});
