<template>
  <transition name="fade" mode="out-in">
    <div class="add-block" v-if="isVisible && $mq === 'xl'">
      <div class="heading-4 label">
        <span>Контент, конкурсы и актуальная информация здесь</span>
        <svg viewBox="0 0 68 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4 16H64M64 16L52 4M64 16L52 28"
            stroke="#181818"
            stroke-width="2.5"
            stroke-linecap="square"
          />
        </svg>
      </div>
      <div class="button-block">
        <IBSButton
          variant="accent outline rounded"
          url="https://www.twitch.tv/ibscyberleague"
          >Twitch
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_7436_6452)">
              <path
                d="M6.40234 1.20117L2.40234 5.05831V18.944H7.20234V22.8012L11.2023 18.944H14.4023L21.6023 12.0012V1.20117H6.40234ZM20.0023 11.2297L16.8023 14.3155H13.6023L10.8023 17.0155V14.3155H7.20234V2.74403H20.0023V11.2297Z"
                fill="#181818"
              />
              <path
                d="M17.6039 5.44385H16.0039V10.0724H17.6039V5.44385Z"
                fill="#181818"
              />
              <path
                d="M13.2016 5.44385H11.6016V10.0724H13.2016V5.44385Z"
                fill="#181818"
              />
            </g>
            <defs>
              <clipPath id="clip0_7436_6452">
                <rect
                  width="19.2"
                  height="21.6"
                  fill="white"
                  transform="translate(2.40039 1.2002)"
                />
              </clipPath>
            </defs>
          </svg> </IBSButton
        ><IBSButton
          variant="accent outline rounded"
          url="https://www.youtube.com/channel/UCp1fNk8UmL8M0v8iQFer6iA"
          >YouTube<svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M20.4759 5.22651C21.3545 5.46171 22.0451 6.15217 22.2802 7.03079C22.7052 8.6217 22.707 11.943 22.707 11.943C22.707 11.943 22.707 15.2643 22.2802 16.8552C22.0451 17.7338 21.3545 18.4243 20.4759 18.6594C18.8851 19.0862 12.5029 19.0862 12.5029 19.0862C12.5029 19.0862 6.12073 19.0862 4.52981 18.6594C3.6512 18.4243 2.96073 17.7338 2.72554 16.8552C2.29883 15.2643 2.29883 11.943 2.29883 11.943C2.29883 11.943 2.29883 8.6217 2.72554 7.03079C2.96073 6.15217 3.6512 5.46171 4.52981 5.22651C6.12073 4.7998 12.5029 4.7998 12.5029 4.7998C12.5029 4.7998 18.8851 4.7998 20.4759 5.22651ZM15.7609 11.9437L10.459 15.0046V8.88281L15.7609 11.9437Z"
              fill="#181818"
            />
          </svg> </IBSButton
        ><IBSButton
          variant="accent outline rounded"
          url="https://t.me/+V0zMdaHiO0M2YmQy"
          >Telegram<svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.61325 10.7489C8.44366 8.20867 12.3315 6.534 14.2768 5.72489C19.831 3.4147 20.9851 3.01339 21.7374 3.00014C21.9028 2.99723 22.2728 3.03823 22.5124 3.23267C22.7147 3.39685 22.7704 3.61863 22.797 3.77429C22.8237 3.92995 22.8568 4.28455 22.8305 4.56163C22.5295 7.7241 21.2271 15.3986 20.5646 18.9406C20.2842 20.4394 19.7322 20.9419 19.1977 20.9911C18.0363 21.098 17.1544 20.2235 16.0295 19.4861C14.2692 18.3323 13.2748 17.614 11.5661 16.488C9.59149 15.1868 10.8716 14.4716 11.9969 13.3027C12.2914 12.9969 17.4087 8.34226 17.5078 7.92001C17.5202 7.86721 17.5316 7.67036 17.4147 7.56642C17.2978 7.46247 17.1252 7.49802 17.0006 7.52629C16.8241 7.56636 14.012 9.42501 8.5645 13.1022C7.76631 13.6503 7.04334 13.9174 6.39558 13.9034C5.68148 13.888 4.30783 13.4996 3.28666 13.1677C2.03416 12.7606 1.0387 12.5453 1.12538 11.8539C1.17053 11.4937 1.66649 11.1254 2.61325 10.7489Z"
              fill="#181818"
            />
          </svg>
        </IBSButton>
      </div>

      <svg
        @click="isVisible = false"
        class="close-add"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.3337 8.5465L23.4537 6.6665L16.0003 14.1198L8.54699 6.6665L6.66699 8.5465L14.1203 15.9998L6.66699 23.4532L8.54699 25.3332L16.0003 17.8798L23.4537 25.3332L25.3337 23.4532L17.8803 15.9998L25.3337 8.5465Z"
          fill="#181818"
        />
      </svg>
    </div>
    <div class="add-block" v-if="isVisible && $mq !== 'xl'">
      <div class="top-block">
        <div class="label">
          <span>Контент, конкурсы и актуальная информация здесь ↓↓↓</span>
        </div>
        <svg
          @click="isVisible = false"
          class="close-add"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.3337 8.5465L23.4537 6.6665L16.0003 14.1198L8.54699 6.6665L6.66699 8.5465L14.1203 15.9998L6.66699 23.4532L8.54699 25.3332L16.0003 17.8798L23.4537 25.3332L25.3337 23.4532L17.8803 15.9998L25.3337 8.5465Z"
            fill="#181818"
          />
        </svg>
      </div>
      <div class="button-block">
        <IBSButton
          size="small"
          variant="accent outline rounded"
          url="https://www.twitch.tv/ibscyberleague"
          >Twitch
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_7436_3088)">
              <path
                d="M4.43449 0.800537L1.76782 3.37197V12.6291H4.96782V15.2005L7.63449 12.6291H9.76782L14.5678 8.00054V0.800537H4.43449ZM13.5012 7.48625L11.3678 9.54339H9.23449L7.36782 11.3434V9.54339H4.96782V1.82911H13.5012V7.48625Z"
                fill="#181818"
              />
              <path
                d="M11.9022 3.62891H10.8356V6.71462H11.9022V3.62891Z"
                fill="#181818"
              />
              <path
                d="M8.9673 3.62891H7.90063V6.71462H8.9673V3.62891Z"
                fill="#181818"
              />
            </g>
            <defs>
              <clipPath id="clip0_7436_3088">
                <rect
                  width="12.8"
                  height="14.4"
                  fill="white"
                  transform="translate(1.76685 0.800049)"
                />
              </clipPath>
            </defs>
          </svg> </IBSButton
        ><IBSButton
          size="small"
          variant="accent outline rounded"
          url="https://www.youtube.com/channel/UCp1fNk8UmL8M0v8iQFer6iA"
          >YouTube<svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.3173 3.48442C13.903 3.64122 14.3634 4.10153 14.5201 4.68727C14.8035 5.74788 14.8046 7.96207 14.8046 7.96207C14.8046 7.96207 14.8046 10.1763 14.5201 11.2369C14.3634 11.8226 13.903 12.2829 13.3173 12.4397C12.2567 12.7242 8.0019 12.7242 8.0019 12.7242C8.0019 12.7242 3.74715 12.7242 2.68654 12.4397C2.1008 12.2829 1.64049 11.8226 1.48369 11.2369C1.19922 10.1763 1.19922 7.96207 1.19922 7.96207C1.19922 7.96207 1.19922 5.74788 1.48369 4.68727C1.64049 4.10153 2.1008 3.64122 2.68654 3.48442C3.74715 3.19995 8.0019 3.19995 8.0019 3.19995C8.0019 3.19995 12.2567 3.19995 13.3173 3.48442ZM10.174 7.96256L6.63932 10.0031V5.92196L10.174 7.96256Z"
              fill="#181818"
            />
          </svg> </IBSButton
        ><IBSButton
          size="small"
          variant="accent outline rounded"
          url="https://t.me/+V0zMdaHiO0M2YmQy"
          >Telegram<svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.57575 7.16592C6.46268 5.47244 9.05458 4.356 10.3514 3.81659C14.0543 2.27646 14.8237 2.00893 15.3252 2.00009C15.4355 1.99815 15.6821 2.02549 15.8418 2.15511C15.9767 2.26457 16.0138 2.41242 16.0316 2.5162C16.0493 2.61997 16.0715 2.85637 16.0539 3.04108C15.8532 5.1494 14.985 10.2657 14.5433 12.6271C14.3564 13.6262 13.9884 13.9613 13.6321 13.9941C12.8578 14.0653 12.2698 13.4823 11.5199 12.9908C10.3464 12.2215 9.68342 11.7426 8.54432 10.992C7.22791 10.1245 8.08128 9.64771 8.83151 8.86849C9.02784 8.66457 12.4394 5.56151 12.5054 5.28001C12.5137 5.2448 12.5213 5.11357 12.4434 5.04428C12.3654 4.97498 12.2504 4.99868 12.1673 5.01752C12.0496 5.04424 10.1749 6.28334 6.54324 8.73483C6.01112 9.10023 5.52914 9.27826 5.0973 9.26893C4.62123 9.25865 3.70546 8.99976 3.02469 8.77846C2.18968 8.50704 1.52604 8.36353 1.58383 7.90257C1.61393 7.66247 1.94457 7.41692 2.57575 7.16592Z"
              fill="#181818"
            />
          </svg>
        </IBSButton>
      </div></div
  ></transition>
</template>

<script setup>
import IBSButton from "@/components/IBSButton.vue";
import { ref, onMounted } from "vue";

const isVisible = ref(false);

onMounted(() => {
  setTimeout(() => {
    isVisible.value = true;
  }, 10000);
});
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
