<template>
  <div class="ibs-button">
    <b-button @click="handleClick" :variant="variant" :class="buttonClass">
      <div class="button-content">
        <slot></slot>
        <div class="icon" v-if="withIcon">
          <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.4993 0.166626L10.3243 1.34163L14.141 5.16663H0.666016V6.83329H14.141L10.316 10.6583L11.4993 11.8333L17.3327 5.99996L11.4993 0.166626Z"
              fill="#ECE8E1"
            />
          </svg>
        </div>
      </div>
    </b-button>
  </div>
</template>
<script>
export default {
  name: "IBSButton",
  props: {
    variant: {
      type: String,
      default: "primary",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "standart",
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      default: "",
    },
  },
  computed: {
    buttonClass() {
      if (this.disabled) return `ibs-button--${this.size} soft-disabled`;
      return `ibs-button--${this.size}`;
    },
  },
  methods: {
    handleClick() {
      if (this.url) this.openLink();
      this.$emit("click");
    },
    openLink() {
      const openURL = window.document.createElement("a");
      openURL.href = this.url;
      openURL.target = "_blank";
      document.body.appendChild(openURL);
      openURL.click();
      document.body.removeChild(openURL);
    },
  },
};
</script>
