<template>
  <div class="stream-block">
    <div class="container">
      <div class="stream-left">
        <div class="stream-title">Прямые трансляции</div>
        <div class="stream-description">
          Болей за коллег или следи за&nbsp;соперниками в прямом эфире
        </div>
        <div class="stream-cards">
          <div class="stream-card">
            <div class="title">Профессионалы</div>
            <div class="description">
              Прямые трансляции с&nbsp;комментаторами не&nbsp;дадут пропустить
              ни&nbsp;одного клатча и&nbsp;камбека. Обсуждайте игру соперников!
              Узнавай тактики и&nbsp;приемы!
            </div>
          </div>
          <div class="stream-card">
            <div class="title">Призы за набранные баллы</div>
            <div class="description">
              Зарабатывай баллы за просмотр матчей, комментарии и задания, а
              после трать их на приятные призы. Наслаждайся шоу по-полному!
            </div>
          </div>
        </div>
        <IBSButton
          variant="secondary rounded"
          url="https://www.twitch.tv/ibscyberleague"
          >Перейти на Twitch</IBSButton
        >
      </div>
      <div class="stream-right">
        <img src="@/assets/images/png/stream-block-content.png" />
      </div>
    </div>
  </div>
</template>
<script>
import IBSButton from "@/components/IBSButton.vue";
export default {
  name: "StreamBlock",
  components: { IBSButton },
};
</script>
