import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import BootstrapVue from "bootstrap-vue";
import Vuelidate from "vuelidate";
import YmapPlugin from "vue-yandex-maps";
import VueMq from "vue-mq";
import "@/assets/scss/index.scss";

Vue.use(BootstrapVue);

Vue.config.productionTip = false;
Vue.use(YmapPlugin);
Vue.use(Vuelidate);
Vue.use(VueMq, {
  breakpoints: {
    xs: 479,
    sm: 767,
    md: 899,
    lg: 1230,
    xl: Infinity,
  },
  defaultBreakpoint: "lg",
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
