<template>
  <div class="about-block">
    <div class="background-block">
      <div class="left"></div>
      <div class="right"></div>
    </div>
    <div :class="isDesktop ? 'container' : 'content-block'">
      <div :class="isDesktop ? 'content-block' : 'container'">
        <div class="about-text">
          С 2020 года мы зажигаем азарт киберспортивных баталий в&nbsp;компании,
          проводя турниры по&nbsp;Counter-Strike. Зародившись как&nbsp;дружеские
          состязания между отделами, сегодня эти&nbsp;события перерастают
          в&nbsp;новый уровень.<br /><br />Мы&nbsp;объединяем сотрудников,
          развивая киберспорт и&nbsp;командный дух. Вместе мы&nbsp;учимся
          доверять друг другу, действуя как&nbsp;единое целое, будь
          то&nbsp;виртуальное поле боя или&nbsp;важный рабочий проект.<br /><br />Станьте
          частью киберспортивного сообщества нашей компании. Участвуйте
          в&nbsp;турнирах, оттачивайте мастерство, заводите друзей
          и&nbsp;заряжайтесь драйвом побед!<br /><br />
          Вместе мы&nbsp;создаем будущее киберспорта!
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  computed: {
    isDesktop() {
      return this.$mq === "xl" || this.$mq === "xxl";
    },
  },
};
</script>
