<template>
  <div class="main-view">
    <HeadingBlock />
    <MoneyBlock />
    <RulesBlock />
    <FormatBlock />
    <ApplyRulesBlock />
    <StreamBlock />
    <AboutBlock />
    <FameBlock />
    <BottomBlock />
    <ApplyModal :modal-id="applyModalId" />
    <AddBlock />
  </div>
</template>

<script>
import HeadingBlock from "@/components/landing/HeadingBlock.vue";
import RulesBlock from "@/components/landing/RulesBlock.vue";
import FormatBlock from "@/components/landing/FormatBlock.vue";
import StreamBlock from "@/components/landing/StreamBlock.vue";
import ApplyModal from "@/components/ApplyModal.vue";
import MoneyBlock from "@/components/landing/MoneyBlock.vue";
import AboutBlock from "@/components/landing/AboutBlock.vue";
import FameBlock from "@/components/landing/FameBlock.vue";
import BottomBlock from "@/components/landing/BottomBlock.vue";
import AddBlock from "@/components/landing/AddBlock.vue";
import ApplyRulesBlock from "@/components/landing/ApplyRulesBlock.vue";

export default {
  name: "MainView",
  components: {
    HeadingBlock,
    RulesBlock,
    FormatBlock,
    StreamBlock,
    ApplyModal,
    MoneyBlock,
    ApplyRulesBlock,
    AboutBlock,
    FameBlock,
    BottomBlock,
    AddBlock,
  },
  data: () => ({
    applyModalId: "apply-modal",
  }),
  mounted() {
    if (this.$route.query?.applied) this.$bvModal.show(this.applyModalId);
  },
};
</script>
