<template>
  <div class="heading-block">
    <div class="background-block">
      <div class="left"></div>
      <div class="right"></div>
    </div>
    <b-container class="content-block">
      <div class="logo">
        <img src="@/assets/images/svg/ibs-logo-big.svg" />
        <div class="text">
          <img src="@/assets/images/svg/small-orange-shield.svg" />
          <div class="league-name">Cyber League</div>
        </div>
      </div>
      <div class="main-heading">
        <div class="first-line">КОРПОРАТИВНЫЙ</div>
        <div class="second-line">КИБЕРТУРНИР по cs2</div>
      </div>
      <div class="info-block">
        Погрузись вместе с коллегами в соревновательный мир видеоигр, общайтесь,
        выстраивайте взаимодействие и двигайтесь к целям вместе!
      </div>
      <!-- <div class="date-block">Регистрация открыта до 29 апреля!</div> -->
      <div class="button-block">
        <!-- <button class="special" @click="$router.push('/apply')">
          Зарегистрироваться
        </button> -->
        <IBSButton variant="secondary rounded" withIcon url="https://ibs.ru/"
          >На сайт IBS</IBSButton
        >
      </div>
    </b-container>
  </div>
</template>

<script>
import IBSButton from "@/components/IBSButton.vue";
export default {
  name: "HeadingBlock",
  components: { IBSButton },
};
</script>
