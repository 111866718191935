import axios from "axios";
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST;

class MatchesApi {
  async loadImage(file) {
    try {
      const result = await axios.post(
        "/save_image/",
        { image: file },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return result.data;
    } catch (err) {
      console.log(err);
      alert(`Ошибка запроса ${err.message}`);
    }
  }
  async loadTournamentMatches(id = 1) {
    try {
      const result = await axios.get(`/tournaments/${id}/matches`);
      return result.data;
    } catch (err) {
      console.log(err);
      alert(`Ошибка запроса ${err.message}`);
    }
  }
  async loadTournaments() {
    try {
      const result = await axios.get("/tournaments");
      return result.data;
    } catch (err) {
      console.log(err);
      return Promise.reject();
    }
  }
}
export default new MatchesApi();
