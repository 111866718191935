<template>
  <div class="format-block">
    <div class="container">
      <div class="format-title">формат проведения</div>
      <div class="format-carousel">
        <div class="left-shadow" />
        <div class="right-shadow" />
        <VueSlickCarousel
          ref="topCarousel"
          @beforeChange="beforeCarouselChangeTop"
          @init="setInit"
          :dots="false"
          :arrows="false"
          :slidesToScroll="1"
          :slidesToShow="1"
          :variableWidth="!isSingleSlide"
          :autoplay="false"
          :infinite="true"
          :rows="1"
          :initialSlide="1"
          :centerMode="!isSingleSlide"
        >
          <div class="format-card card-3" key="1">
            <div class="left">
              <div
                class="background-highlight"
                :class="{ active: carouselIndex === 0 }"
              />
              <img src="@/assets/images/png/prize-3d.png" />
              <div class="bottom">
                <div class="stage-block">
                  <div class="stage-index">3 этап</div>
                  <div class="stage-name">гранд-финал</div>
                </div>
                <div class="match-length">ФОРМАТ BO3</div>
              </div>
            </div>
            <div class="right">
              <div class="stage-heading">Золотая и серебряная лиги</div>
              <div class="stage-description">
                За&nbsp;победу сражаются две лучшие команды из&nbsp;верхней
                и&nbsp;нижней сетки. Победитель забирает первенство
                в&nbsp;сезоне, а&nbsp;проигравший занимает второе место.<br /><br />
                Третье место определяется в&nbsp;последнем матче нижней сетки.
              </div>
            </div>
          </div>

          <div class="format-card card-2" key="1">
            <div class="left">
              <div
                class="background-highlight"
                :class="{ active: carouselIndex === 1 }"
              />
              <img src="@/assets/images/png/shield-3d.png" />
              <div class="bottom">
                <div class="stage-block">
                  <div class="stage-index">1 этап</div>
                  <div class="stage-name">групповой</div>
                </div>
                <div class="match-length">ФОРМАТ BO1</div>
              </div>
            </div>
            <div class="right">
              <div class="stage-heading">Общий для&nbsp;всех команд</div>
              <div class="stage-description">
                Round Robin — каждая команда играет с&nbsp;каждой в&nbsp;своей
                группе. По&nbsp;итогам этапа лучшие отправляются в&nbsp;Золотую
                лигу, другие — в&nbsp;Серебряную.<br /><br />
                Исходя из&nbsp;общего количества участников, команды
                с&nbsp;наименьшим количеством баллов могут не&nbsp;пройти
                в&nbsp;следующий этап.
              </div>
            </div>
          </div>

          <div class="format-card card-1" key="1">
            <div class="left">
              <div
                class="background-highlight"
                :class="{ active: carouselIndex === 2 }"
              />
              <img src="@/assets/images/png/leagues-3d.png" />
              <div class="bottom">
                <div class="stage-block">
                  <div class="stage-index">2 этап</div>
                  <div class="stage-name">ПЛЕй-офф</div>
                </div>
                <div class="match-length">ФОРМАТ BO3</div>
              </div>
            </div>
            <div class="right">
              <div class="stage-heading">Золотая и серебряная лиги</div>
              <div class="stage-description">
                Double Elimination — выбывание после двух поражений. После
                первого проигрыша команда падает в&nbsp;нижнюю сетку вместе
                с&nbsp;остальными побежденными.<br /><br />
                Проигравшие в&nbsp;нижней сетке покидают турнир окончательно,
                победители имеют шанс снова побороться за&nbsp;главный приз.
              </div>
            </div>
          </div>
        </VueSlickCarousel>
        <div class="controls">
          <IBSButton
            variant="secondary rounded"
            @click="topPrev"
            size="small"
            :isDisabled="isTopPrevDisabled"
            ><svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.3334 10H4.66669M4.66669 10L10.5 15.8333M4.66669 10L10.5 4.16667"
                stroke="#ECE8E1"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              /></svg
            >Назад</IBSButton
          >
          <IBSButton
            variant="secondary rounded small"
            @click="topNext"
            size="small"
            :isDisabled="isTopNextDisabled"
            >Вперёд<svg
              width="21"
              height="20"
              viewBox="0 0 21 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.66669 10H16.3334M16.3334 10L10.5 4.16667M16.3334 10L10.5 15.8333"
                stroke="#ECE8E1"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </IBSButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import IBSButton from "@/components/IBSButton.vue";
export default {
  name: "FormatBlock",
  data: () => ({
    carouselIndex: 1,
  }),
  components: {
    IBSButton,
    VueSlickCarousel,
  },
  computed: {
    isTopPrevDisabled() {
      return this.carouselIndex === 0;
    },
    isSingleSlide() {
      return this.$mq !== "xl" && this.$mq !== "xxl";
    },
    isTopNextDisabled() {
      if (this.carouselIndex === 2) return true;
      if (window.screen.width > 1138 && this.carouselIndex === 1) return true;
      return false;
    },
  },
  methods: {
    beforeCarouselChangeTop(oldPage, newPage) {
      this.carouselIndex = newPage;
    },
    topNext() {
      this.$refs.topCarousel.next();
    },
    topPrev() {
      this.$refs.topCarousel.prev();
    },
    bottomNext() {
      this.$refs.bottomCarousel.next();
    },
    bottomPrev() {
      this.$refs.bottomCarousel.prev();
    },
    setInit() {
      this.carouselIndex = 1;
    },
  },
};
</script>
